import itemSer from 'public/src/services/goodsItemInfo/interfaceSer'

class BlackFridayImg {
  constructor() {
    this.BlackFridayImgGoods = {}
  }

  init() {
    try {
      let cartBlackFridayImgGoods = ''
      if ( typeof window != 'undefined' && window.sessionStorage ) {
        cartBlackFridayImgGoods = sessionStorage.getItem(`${ gbCommonInfo.SiteUID }_cart_black_friday_img_goods`)
      }
      if ( cartBlackFridayImgGoods ) {
        return JSON.parse(cartBlackFridayImgGoods)
      }
    } catch ( e ) {
      console.log(e)
    }
    return {}
  }

  async getBlackFridayImg({ goods = [], machineLabel = [], cart_goods_label = '' } = {}) {
    let newGoods = goods.filter(item => !this.BlackFridayImgGoods[item.goods_id])
    if ( !newGoods.length ) return this.BlackFridayImgGoods
    const labelsBeforeGoodsName = cart_goods_label !== '' && cart_goods_label !== 'NO'
    try {
      let blackFridayImgGoods = await itemSer.getAtomicInfo({
        goods: newGoods,
        needAssign: false,
        fields: {
          promoLabel: true,
          realTimeTspLabels: {
            machine_label: machineLabel,
          },
          labelsBeforeGoodsName,
        },
        cccParams: {
          labelsBeforeGoodsNameParams: {
            abtBranch: cart_goods_label,
          }
        },
        pageKey: 'page_cart',
        subPageKey: 'one_image_cart'
      })

      this.BlackFridayImgGoods = Object.assign({}, this.BlackFridayImgGoods, blackFridayImgGoods)
      // if ( window.sessionStorage ) {
      //   sessionStorage.setItem(`${ gbCommonInfo.SiteUID }_cart_black_friday_img_goods`, JSON.stringify(this.BlackFridayImgGoods))
      // }
    } catch ( e ) {
      console.log(e)
    }

    return this.BlackFridayImgGoods
  }
}

export default new BlackFridayImg()
