import { template } from '@shein/common-function'
/** 处理购物车底部展示的免邮模块 */

/**
 * 判断是否为指定免邮券
 * @param {Object} coupon 券信息
 * @returns {Boolean}
 */
export const isFreeShipCoupon = (coupon) => {
  return coupon?.apply_for == '9' && coupon?.shipping_discount_type == 0
}

/**
 * 从freemall获取运输方式类型
 * @param {Array} mall_transport_free_list
 * @returns {Array}
 */
export const getTransportTypesFromFreeMall = (mall_transport_free_list, is_free_shipping) => {
  if (!mall_transport_free_list?.length) return []
  let arr = mall_transport_free_list?.map(v => v.transport_free_list)?.flat()
  if (typeof is_free_shipping === 'number') arr = arr?.filter(v => v?.is_free_shipping === is_free_shipping)
  arr = arr?.map(v => v?.transport_type)
  return Array.from(new Set(arr))
}

/**
 * 从freemall获取已免邮的运输方式名称
 * @param {Array} mall_transport_free_list
 * @returns {Array}
 */
export const getTransportNamesFromFreeMall = (mall_transport_free_list) => {
  if (!mall_transport_free_list?.length) return []
  const arr = mall_transport_free_list?.map(v => v.transport_free_list)?.flat()?.filter(v => v?.is_free_shipping === 1)?.map(v => v?.shipping_method_name)
  return Array.from(new Set(arr))
}


/**
 * 从券获取运输方式类型
 * @param {Array} mall_transport_free_list
 * @returns {Array}
 */
export const getTransportTypesFromCoupons = (coupons, mallTransportFreeList = []) => {
  if (!coupons?.length) return []
  const set = new Set()
  coupons.map(v => {
    if (!v.shipping_list?.length) return mallTransportFreeList
    return v.shipping_list
  })?.flat()?.forEach(v => {
    if (mallTransportFreeList.includes(v.shipping_method_code)) set.add(v.shipping_method_code)
  })
  return [...set.values()]
}

/**
 * 格式化免邮券信息
 * @param {Object} c 券信息
 * @param {Object} language 多语言
 * @returns {Object} 券免邮信息
 */
const formatFreeShippingModule = ({ type, info, language }) => {
  let result = {}

  if (type === 'coupon') {
    const { coupon, shipping_list, needPrice, satisfied_range } = info || {}
    const methodsStr = shipping_list?.filter(v => !!v?.shipping_method_name)?.map(item => item?.shipping_method_name)?.join(', ')
    const showShipSupplement = satisfied_range == 0 // 是否满足门槛
    const freeShippinpTips = showShipSupplement 
      ? (methodsStr ? template(needPrice?.amountWithSymbol, methodsStr, language.SHEIN_KEY_PWA_29787) : template(needPrice?.amountWithSymbol, language.SHEIN_KEY_PWA_29789))
      : template(coupon, language.SHEIN_KEY_PWA_29790)
    
    result = {
      showShipSupplement,
      freeShippinpTips,
      countDownTime: null,
      showCountDown: false,
      lackPrice: needPrice,
    }
  } else if (type === 'activity') {
    const { isFirstFreeShipping, firstFreeShipping, shippingTip, showShipSupplement, lackPrice } = info || {}
    const freeShippinpTips = showShipSupplement 
      ? isFirstFreeShipping ? firstFreeShipping?.firstFreeShippingTip : shippingTip
      : language.SHEIN_KEY_PWA_29788

    result = {
      showShipSupplement,
      freeShippinpTips,
      showCountDown: !!firstFreeShipping?.showCountDown,
      countDownTime: firstFreeShipping?.countDownTime,
      lackPrice,
    }
  }

  return {
    ...result,
    type,
    _originInfo: info,
  }
}

/**
 * 筛选展示的免邮是活动还是指定免邮券
 * @param {Object} coupon 券信息
 * @param {Object} activity 免邮活动信息
 * @returns {Object}
 */
export const selectFreeShippingModule = (coupon, activity, language) => {
  if (!coupon && !activity) return null
  if (!coupon && activity) {
    return formatFreeShippingModule({
      type: 'activity',
      info: activity,
      language
    })
  }
  if (coupon && !activity) {
    return formatFreeShippingModule({
      type: 'coupon',
      info: coupon,
      language
    })
  }
  // activity_type=3为运费活动，优先凑券
  const showFreeShipCoupon = activity?.activity_type === 3 || Number(coupon?.need_price) < Number(activity?.lackPrice?.amount)
  return formatFreeShippingModule({
    type: showFreeShipCoupon ? 'coupon' : 'activity',
    info: showFreeShipCoupon ? coupon : activity,
    language,
  })
}
