import { getSiteMallInfo } from 'public/src/pages/common/getSiteMallInfo'
import schttp from 'public/src/services/schttp'
import { getLocalStorage } from '@shein/common-function'

export const matchStoreJumpUrl = (params) => {
  return schttp({
    url: `/api/store/matchStoreJumpUrl/get`,
    params: {
      storeCode: params.join(',')
    }
  }).then( res => {
    let result = {}
    if (res.code == 0) {
      result = res?.links || {}
    }
    return result
  }).catch( () => {
    return {}
  })
}

let postModifyCartCheckStatusAbortController
export const modifyCartCheckStatus = (params = {}) => {
  postModifyCartCheckStatusAbortController?.abort()
  postModifyCartCheckStatusAbortController = new SchttpAbortCon()
  if(params.editCartId) delete params.editCartId
  return schttp({
    url: `/api/cart/postModifyCartCheckStatus/update`,
    method: 'POST',
    data: {
      ...params,
      cart_prime_product_code: window.cart_prime_product_code || ''
    },
    signal: postModifyCartCheckStatusAbortController?.signal
  }).catch(err => {
    if (err.code === 'ERR_CANCELED') return err
    return { code: -1 }
  })
}

export const updateCart =  (params = {}) => {
  return schttp({
    url: '/api/cart/checkcart/get',
    params: {
      ...params,
      cart_prime_product_code: window.cart_prime_product_code || ''
    }
  })
}

export const cartLoginPromise = ({ type = '', config = {} }) => {
  const scenes = {
    'checkout': 'checkout',
    'wishlist': 'wishlist',
    'empty': 'cart',
    'cart': 'cart',
  }

  const scene = scenes[type] || 'other'

  return new Promise(resolve => {
    SHEIN_LOGIN.show({
      show: true,
      reload: false,
      bi: scene,
      ...config,
      cb () {
        resolve()
      }
    })
  })
}

export const getGiftProductList = ({ promotion_id = '', range = '' }) =>{
  const requestData = {
    promotion_id: promotion_id,
    range: range,
    limit: 20,
    page: 1
  }
  return schttp({
    url: '/api/cart/postGetGiftProductList/get',
    method: 'POST',
    data: requestData
  })
}

export const queryMallInfo = async () => {
  let isPlatForm = false
  try {
    let { mallInfoForSite } =  await getSiteMallInfo()
    if (mallInfoForSite?.length > 1) {
      isPlatForm = true
    }
    return ({ isPlatForm, siteMallList: mallInfoForSite || [] })
  } catch (e) {
    return Promise.resolve({ isPlatForm, siteMallList: [] })
  }
}

export const getSwitchPopover = (params = []) => {
  return schttp({
    url: '/api/cart/postSpuProductsCart/get',
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      accept: 'application/json, text/plain, */*'
    },
    data: params
  })
}

/**
 * 获取购物车商品数量
 * @param {Object} params amount
 */
export const queryPaymentFrontShowForCartIndex = ({ amount = 0 }) =>{
  const addressCookie = getLocalStorage('addressCookie')
  const cache = typeof addressCookie == 'string' ? JSON.parse(addressCookie) : addressCookie
  if(!cache?.countryId){
    return Promise.resolve({ code: -1, info: {} })
  }
  const requestData = {
    amount,
    countryId: cache.countryId
  }
  return schttp({
    url: '/api/cart/queryPaymentFrontShowForCartIndex/get',
    method: 'POST',
    data: requestData
  })
}

export const getUniversalCountryList = () => {
  return schttp({
    url: '/api/user/addressbook/universalCountryList/get'
  }).then( res => {
    const allCountry = new Map()
    let _country = res.code == 0 ? res.info.res : {}
    if (_country.item_country) {
      _country.item_country.forEach((item) => {
        allCountry.set(item.id, item.value)
      })
    }
    return allCountry
  })
}
