import { template } from '@shein/common-function'
import { percentNum, percentNumWithoutSign } from 'public/src/pages/common/promotion/utils/common.js'
export function getTipTexts(promo, language = {}) {
  const { typeId, rules } = promo
  const { range, diff, hit_range } = promo?.promotion_range_info || {}
  if(typeId == 14) { // 全场满减和部分满减文案相同
    const type = rules[0].type
    // 是否每满活动
    const isEveryFull = type == 2 || type == 4
    const maxDiscount = rules?.[rules?.length - 1]?.discount?.value_amount?.amountWithSymbol
    const nextRule = isEveryFull ? rules[0] : rules[range] // 下一档, 凑单档位
    const rule = range > 0 && !isEveryFull ? rules[range - 1] : rules[0] // 以满足的档位

    // 底部已享受权益文案
    let earnedBenefitsText = ''
    if (isEveryFull) {
      if (hit_range?.total_discount_amount?.amount > 0) {
        earnedBenefitsText = template(
          `<em>${ hit_range?.total_discount_amount?.amountWithSymbol }</em>`,
          language.SHEIN_KEY_PWA_27751
        )
      }
    }

    // 进度条文案
    let processText = ''
    // 进度条增长未使档次提升气泡提示
    let closeBubbleText = template(
      `<em>${ diff?.value }</em>`,
      `<em>${ nextRule?.discount?.value_amount?.amountWithSymbol }</em>`,
      language.SHEIN_KEY_PWA_27822
    )
    // 进度条增长使档次提升气泡提示
    let reachBubbleText = template(
      `<em>${ rule?.discount?.value_amount?.amountWithSymbol }</em>`,
      language.SHEIN_KEY_PWA_27829
    )
    if (type == 2) { // 每满X件
      // 是否已满足最大优惠限制
      const isFullMaxDiscount = rule?.max_discount > 0 && hit_range?.total_discount_amount?.amount >= rule?.max_discount
      if (range <= 0) {
        processText = template(
          `<em>${ diff?.value }</em>`,
          `<em>${ rule?.discount?.value_amount?.amountWithSymbol }</em>`,
          language.SHEIN_KEY_PWA_29557
        )
      } else if (isFullMaxDiscount) {
        processText = template(
          language.SHEIN_KEY_PWA_29560
        )
      } else if (diff?.value == rule?.value) {
        processText = template(
          `<em>${ hit_range?.total_discount_amount?.amountWithSymbol }</em>`,
          language.SHEIN_KEY_PWA_29558
        )
      } else if (diff?.value < rule?.value) {
        processText = template(
          `<em>${ diff?.value }</em>`,
          `<em>${ rule?.discount?.value_amount?.amountWithSymbol }</em>`,
          language.SHEIN_KEY_PWA_29559
        )
      }

      closeBubbleText = template(
        `<em>${ diff?.value }</em>`,
        language.SHEIN_KEY_PWA_29562
      )
      reachBubbleText = template(
        language.SHEIN_KEY_PWA_29563
      )
    } else if (type == 4) { // 每满X元
      // 是否已满足最大优惠限制
      const isFullMaxDiscount = rule?.max_discount > 0 && hit_range?.total_discount_amount?.amount >= rule?.max_discount
      if (range <= 0) {
        processText = template(
          `<em>${ diff?.value_amount?.amountWithSymbol }</em>`,
          `<em>${ rule?.discount?.value_amount?.amountWithSymbol }</em>`,
          language.SHEIN_KEY_PWA_29557
        )
      } else if (isFullMaxDiscount) {
        processText = template(
          language.SHEIN_KEY_PWA_29560
        )
      } else {
        processText = template(
          `<em>${ diff?.value_amount?.amountWithSymbol }</em>`,
          `<em>${ rule?.discount?.value_amount?.amountWithSymbol }</em>`,
          language.SHEIN_KEY_PWA_29559
        )
      }

      closeBubbleText = template(
        `<em>${ diff?.value_amount?.amountWithSymbol }</em>`,
        language.SHEIN_KEY_PWA_29562
      )
      reachBubbleText = template(
        language.SHEIN_KEY_PWA_29563
      )
    }
    return {
      topText: template(maxDiscount, language.SHEIN_KEY_PWA_27817),
      closeBubbleText,
      reachBubbleText,
      earnedBenefitsText,
      processText,
    }
  } else if (typeId == 26) { // 全场满折
    const nextRule = rules[range]
    const rule = range > 0 ? rules[range - 1] : rules[0] // 以满足的档位
    const maxDiscount = percentNum(rules?.[rules?.length - 1]?.discount?.value || 0)
    if(promo?.rules?.[0]?.discount?.type == 3) { // 按特价打折
      return {
        topText: template(
          maxDiscount,
          language.SHEIN_KEY_PWA_27819.replace('%', '')
        ),
        closeBubbleText: template(
          `<em>${ diff?.value }</em>`,
          `<em>${ percentNum(nextRule?.discount?.value || 0) }</em>`,
          language.SHEIN_KEY_PWA_27824.replace('%', '')
        ),
        reachBubbleText: template(
          `<em>${ percentNum(rule?.discount?.value || 0) }</em>`,
          language.SHEIN_KEY_PWA_27831.replace('%', '')
        ),
      }
    } else { // 按原价打折
      return {
        topText: template(
          maxDiscount,
          language.SHEIN_KEY_PWA_27818.replace('%', '')
        ),
        closeBubbleText: template(
          `<em>${ diff?.value }</em>`,
          `<em>${ percentNum(nextRule?.discount?.value || 0) }</em>`,
          language.SHEIN_KEY_PWA_27823.replace('%', '')
        ),
        reachBubbleText: template(
          `<em>${ percentNum(rule?.discount?.value || 0) }</em>`,
          language.SHEIN_KEY_PWA_27830.replace('%', '')
        ),
      }
    }
  } else if (typeId == 25) { // 满折
    const nextRule = rules[range]
    const rule = range > 0 ? rules[range - 1] : rules[0] // 以满足的档位
    const maxDiscount = percentNum(rules?.[rules?.length - 1]?.discount?.value || 0)
    if(promo?.rules?.[0]?.discount?.type == 3) { // 按特价折扣
      return {
        topText: template(
          maxDiscount, 
          language.SHEIN_KEY_PWA_27819.replace('%', '')
        ),
        closeBubbleText: template(
          `<em>${ diff?.value }</em>`,
          `<em>${ percentNum(nextRule?.discount?.value || 0) }</em>`,
          language.SHEIN_KEY_PWA_27828.replace('%', '')
        ),
        reachBubbleText: template(
          `<em>${ percentNum(rule?.discount?.value || 0) }</em>`,
          language.SHEIN_KEY_PWA_27831.replace('%', '')
        ),
      } 
    } else { // 按原价打折
      return {
        topText: template(
          maxDiscount, 
          language.SHEIN_KEY_PWA_27818.replace('%', '')
        ),
        closeBubbleText: template(
          `<em>${ diff?.value }</em>`,
          `<em>${ percentNum(nextRule?.discount?.value || 0) }</em>`,
          language.SHEIN_KEY_PWA_27827.replace('%', '')
        ),
        reachBubbleText: template(
          `<em>${ percentNum(rule?.discount?.value || 0) }</em>`,
          language.SHEIN_KEY_PWA_27830.replace('%', '')
        ),
      }
    }
  } else if (typeId == 9) { // 买N折N
    const nextRule = rules[range]
    const rule = range > 0 ? rules[range - 1] : rules[0] // 以满足的档位
    const maxRange = rules?.[rules?.length - 1]
    const maxDiscount = percentNum(maxRange?.discount?.value || 0)
    const maxThreshold = maxRange?.value
    if(promo?.rules?.[0]?.discount?.type == 3) { // 按特价打折
      return {
        topText: template(
          maxThreshold, 
          maxDiscount, 
          language.SHEIN_KEY_PWA_27821.replace('%', '')
        ),
        closeBubbleText: template(
          `<em>${ ((1 - nextRule?.discount?.value) * 100).toFixed(0) }</em>`,
          language.SHEIN_KEY_PWA_27826.replace('%', ''),
        ),
        reachBubbleText: template(
          `<em>${ ((1 - rule?.discount?.value) * 100).toFixed(0) }</em>`,
          language.SHEIN_KEY_PWA_27831.replace('%', ''),
        ),
      }
    } else { // 按原价打折
      if(['hk', 'tw'].includes(gbCommonInfo?.lang)) {
        return {
          topText: template(
            maxThreshold, 
            maxDiscount, 
            language.SHEIN_KEY_PWA_27820.replace('%', '')
          ),
          closeBubbleText: template(
            `<em>${ Number((nextRule?.discount?.value * 10).toFixed(1)) }%</em>`,
            language.SHEIN_KEY_PWA_27825.replace('%', ''),
          ),
          reachBubbleText: template(
            `<em>${ Number((rule?.discount?.value * 10).toFixed(1)) }%</em>`,
            language.SHEIN_KEY_PWA_27830.replace('%', ''),
          ),
        }
      }
      return {
        topText: template(
          maxThreshold, 
          maxDiscount, 
          language.SHEIN_KEY_PWA_27820.replace('%', '')
        ),
        closeBubbleText: template(
          `<em>${ ((1 - nextRule?.discount?.value) * 100).toFixed(0) }%</em>`,
          language.SHEIN_KEY_PWA_27825.replace('%', ''),
        ),
        reachBubbleText: template(
          `<em>${ ((1 - rule?.discount?.value) * 100).toFixed(0) }%</em>`,
          language.SHEIN_KEY_PWA_27830.replace('%', ''),
        ),
      }
    }
  } else if (typeId == 1) {
    const { discount, value } = rules[0]
    let processText = ''
    const hitRangeNum = hit_range?.hit_range_num || 0 // 已享受活动的件数
    if(range <= 0) {
      if(discount.type == '4') { // 按特价
        processText = template(
          `<em>${ value }</em>`, // 门槛
          `<em>${ percentNumWithoutSign(discount.value) }%</em>`, // 折扣
          `<em>${ diff?.value }</em>`, // 差额
          language.SHEIN_KEY_PWA_28578.replaceAll('%', '')
        )
      } else {
        processText = template(
          `<em>${ value }</em>`,
          `<em>${ percentNumWithoutSign(discount.value) }%</em>`,
          `<em>${ diff?.value }</em>`,
          language.SHEIN_KEY_PWA_28575.replaceAll('%', '')
        )
      }
    } else if(diff?.value == value) {
      if(discount.type == '4') {
        processText = template(
          language.SHEIN_KEY_PWA_28669.replaceAll('%', '')
        )
      } else {
        processText = template(
          language.SHEIN_KEY_PWA_28669.replaceAll('%', '')
        )
      }
    } else if(diff?.value <= value) {
      if(discount.type == '4') {
        processText = template(
          `<em>${ diff?.value }</em>`,
          `<em>${ percentNumWithoutSign(discount.value) }%</em>`,
          language.SHEIN_KEY_PWA_28577.replaceAll('%', '')
        )
      } else {
        processText = template(
          `<em>${ diff?.value }</em>`,
          `<em>${ percentNumWithoutSign(discount.value) }%</em>`,
          language.SHEIN_KEY_PWA_28577.replaceAll('%', '')
        )
      }
    }
    const maxDiscount = percentNum(rules?.[rules?.length - 1]?.discount?.value || 0)
    return {
      topText: template(
        maxDiscount,
        language.SHEIN_KEY_PWA_28574.replace('%', '')
      ),
      earnedBenefitsText: hitRangeNum > 0 ? template(
        `<em>${ hitRangeNum }</em>`,
        `<em>${ percentNumWithoutSign(discount.value) }%</em>`,
        language.SHEIN_KEY_PWA_28576.replace('%', '')
      ) : '', // 权益文案
      closeBubbleText: template(
        `<em>${ diff.value }</em>`,
        language.SHEIN_KEY_PWA_28580.replace('%', '')
      ), // 动效4, 接近门槛文案
      reachBubbleText: template(
        `<em>${ hitRangeNum }</em>`,
        `<em>${ percentNumWithoutSign(discount.value) }%</em>`,
        language.SHEIN_KEY_PWA_28581.replace('%', '')
      ), // 动效5 已达门槛文案
      processText, // 进度条文案
    }
  } else if (typeId == 15) {
    // x元n件
    const maxRange = rules?.length ? rules.length - 1 : 0 // 最高档
    const maxBundlePrice = rules?.[maxRange]?.discount?.value_amount?.amountWithSymbol // 最高挡打包购买的金额
    const maxItemsPerBundle = rules?.[maxRange]?.value // 最高档的件数门槛
    const hitRangeNum = hit_range?.hit_range_num || 0 // 已享受活动的件数
    const hitRangePrice = hit_range?.total_discount_amount?.amountWithSymbol || 0 // 已享受活动的总价格

    const { type, discount, value } = rules[0]
    let processText = ''

    // 文案集合
    const textObjList = rules.map(item => {
      // 满x件
      if (item.type == 1 && item.discount?.type == 7) {
        return {
          topText: template(
            maxItemsPerBundle,
            maxBundlePrice,
            language.SHEIN_KEY_PWA_28582
          ),

        }
      // 每满X件
      } else if (item.type == 2 && item.discount?.type == 7) {
        return {
          topText: template(
            maxBundlePrice,
            maxItemsPerBundle,
            language.SHEIN_KEY_PWA_28583
          ),
          earnedBenefitsText: hitRangeNum > 0 ? template(
            `<strong>${hitRangeNum}</strong>`,
            `<strong>${hitRangePrice}</strong>`,
            language.SHEIN_KEY_PWA_28587
          ) : ''
        }
      }
    })

    // 每满x件
    if (type == 2) {
      if(range <= 0) {
        processText = template(
          `<em>${discount?.value_amount?.amountWithSymbol}</em>`,
          `<em>${value}</em>`,
          `<em>${diff?.value}</em>`,
          language.SHEIN_KEY_PWA_28584
        )
      } else if(diff?.value == value) {
        processText = template(
          language.SHEIN_KEY_PWA_28669
        )
      } else if(diff?.value < value) {
        processText = template(
          `<em>${diff?.value}</em>`,
          language.SHEIN_KEY_PWA_28586
        )
      }
    }
    return {
      // 顶部title
      topText: textObjList[maxRange]?.topText || '',
      // 底部已享受权益文案
      earnedBenefitsText: textObjList[0].earnedBenefitsText || '',
      // 进度条增长未使档次提升气泡提示
      closeBubbleText: template(
        `<strong>${ diff.value }</strong>`,
        language.SHEIN_KEY_PWA_28580
      ),
      // 进度条增长使档次提升气泡提示
      reachBubbleText: template(
        `<strong>${ hitRangePrice }</strong>`,
        language.SHEIN_KEY_PWA_28588
      ),
      processText,
    }
  } else return {}
}
